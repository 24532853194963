var domHelpers = require("../../../utils/dom_helpers");
var dropTarget = require("./tasks_grid_dnd_marker_helpers/drop_target");
var getLockedLevelTarget = require("./tasks_grid_dnd_marker_helpers/locked_level");
var getMultiLevelTarget = require("./tasks_grid_dnd_marker_helpers/multi_level");
var higlighter = require("./tasks_grid_dnd_marker_helpers/highlight");

function _init_dnd(gantt, grid) {
	var DnD = gantt.$services.getService("dnd");

	if(!grid.$config.bind || !gantt.getDatastore(grid.$config.bind)){
		return;
	}

	function locate(e){
		return domHelpers.locateAttribute(e, grid.$config.item_attribute);
	}

	var dnd = new DnD(grid.$grid_data, {updates_per_second: 60});
	if (gantt.defined(grid.$getConfig().dnd_sensitivity))
		dnd.config.sensitivity = grid.$getConfig().dnd_sensitivity;

	dnd.attachEvent("onBeforeDragStart", gantt.bind(function (obj, e) {
		var el = locate(e);
		if (!el) return false;
		if (gantt.hideQuickInfo) gantt._hideQuickInfo();

		var id = el.getAttribute(grid.$config.item_attribute);
		var datastore = grid.$config.rowStore;
		var task = datastore.getItem(id);

		if (gantt.isReadonly(task))
			return false;

		dnd.config.initial_open_state = task.$open;
		if (!gantt.callEvent("onRowDragStart", [id, e.target || e.srcElement, e])) {
			return false;
		}

	}, gantt));

	dnd.attachEvent("onAfterDragStart", gantt.bind(function (obj, e) {
		var el = locate(e);

		dnd.config.marker.innerHTML = el.outerHTML;
		var element = dnd.config.marker.firstChild;
		if(element){
			dnd.config.marker.style.opacity = 0.4;
			element.style.position = "static";
			element.style.pointerEvents = "none";
		}
		
		dnd.config.id = el.getAttribute(grid.$config.item_attribute);

		var store = grid.$config.rowStore;

		var task = store.getItem(dnd.config.id);
		dnd.config.level = store.calculateItemLevel(task);
		dnd.config.drop_target = dropTarget.createDropTargetObject({
			targetParent: store.getParent(task.id),
			targetIndex: store.getBranchIndex(task.id),
			targetId: task.id,
			nextSibling: true
		});

		task.$open = false;
		task.$transparent = true;
		this.refreshData();
	}, gantt));

	function getTargetTaskId(e){
		var y = domHelpers.getRelativeEventPosition(e, grid.$grid_data).y;
		var store = grid.$config.rowStore;

		y = y || 0;

		if(y < 0){
			return store.$getRootId();
		}

		var index = Math.floor(y / grid.getItemHeight());

		if (index > store.countVisible() - 1)
			return store.$getRootId();

		return store.getIdByIndex(index);
	}

	function getDropPosition(e){
		var targetTaskId = getTargetTaskId(e);
		var relTargetPos = null;
		var store = grid.$config.rowStore;
		var config = grid.$getConfig();
		var lockLevel = !config.order_branch_free;

		var eventTop = domHelpers.getRelativeEventPosition(e, grid.$grid_data).y;

		if(targetTaskId !== store.$getRootId()) {
			var rowTop = grid.getItemTop(targetTaskId);
			var rowHeight = grid.getItemHeight();
			relTargetPos = (eventTop - rowTop) / rowHeight;
		}

		var result;
		if(!lockLevel){
			result = getMultiLevelTarget(dnd.config.id, targetTaskId, relTargetPos, eventTop, store);
		}else{
			result = getLockedLevelTarget(dnd.config.id, targetTaskId, relTargetPos, eventTop, store, dnd.config.level);
		}

		return result;
	}

	dnd.attachEvent("onDragMove", gantt.bind(function (obj, e) {
		var target = getDropPosition(e);

		if(!target || 
			gantt.callEvent("onBeforeRowDragMove", [dnd.config.id, target.targetParent, target.targetIndex]) === false){
				target = dropTarget.createDropTargetObject(dnd.config.drop_target);
			}
		
		higlighter.highlightPosition(target, dnd.config, grid);
		dnd.config.drop_target = target;

		this.callEvent("onRowDragMove", [dnd.config.id, target.targetParent, target.targetIndex]);
		return true;
	}, gantt));

	dnd.attachEvent("onDragEnd", gantt.bind(function () {
		var store = grid.$config.rowStore;
		var task = store.getItem(dnd.config.id);

		higlighter.removeLineHighlight(dnd.config);

		task.$transparent = false;
		task.$open = dnd.config.initial_open_state;
		var target = dnd.config.drop_target;

		if (this.callEvent("onBeforeRowDragEnd", [dnd.config.id, target.targetParent, target.targetIndex]) === false) {
			task.$drop_target = null;
		} else {
			store.move(dnd.config.id, target.targetIndex, target.targetParent);
			this.callEvent("onRowDragEnd", [dnd.config.id, target.targetParent, target.targetIndex]);
		}
		store.refresh(task.id);
	}, gantt));
}

module.exports = {
	init: _init_dnd
};